@import 'variables.scss';

.form-input {
	position: relative;
    display: flex;
    height: 22px;

    input {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $button-border-color;
        color: $button-border-hover-color;
        outline: none;
        font-size: 1.1rem;
        font-family: "Ubuntu Mono";
        margin-bottom: 1px;
	    width: 100%;

        &:focus, &.active {
            border-bottom: 2px solid $button-border-hover-color;
            margin-bottom: 0px;
        }

        &::-webkit-inner-spin-button {
            opacity: 0.3;
        }

        + label {
	        position: absolute;
	        left: 2px;
	        bottom: 3px;
	        font-size: 1.1rem;
	        color: $button-border-color;
	        transition: 0.2s;
	        cursor: text;
        }

        &:focus + label {
	        font-size: 0.9rem;
	        bottom: 25px;
	        color: $button-border-hover-color;
        }

        + label.active {
	        bottom: 25px !important;
	        font-size: 0.9rem !important;
	        transition: none !important;
        }
    }

    .password-input {
        padding-right: 25px;
    }

    .eye-icon {
        fill: #fff;
        width: 20px;
        margin-left: -18px;
        z-index: 1;
    }
}

