@import 'variables.scss';

.form-button {
    &  > * {
        color: #000;
        background-color: transparent;
        border: 2px solid $button-border-color;
        color: #fff;
        cursor: pointer;
        font-size: 1rem;
        text-decoration: none;
    }

    &  > *:hover {
        border: 2px solid $button-border-hover-color;
        box-shadow: 0 0 4px 1px $button-border-hover-color;
    }

    .disabled {
        color: $disabled;
        border: 2px solid #444;
        cursor: auto;
    }

    .disabled:hover {
        box-shadow: none;
        border: 2px solid #444;
    }
}

