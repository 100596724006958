.not-found-text {
    font-family: windlass, serif;
    font-size: 2rem;
    margin-top: 50px;
}

.confused-gif {
    margin: 50px 0;
}

.go-home-link {
    font-family: windlass, serif;
    font-size: 1.5rem;
    color: #fff;
}

