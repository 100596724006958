@import 'csgModal';

.fixed-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.csg-modal {
    min-width: 999px;
    padding: 20px;

    max-height: 710px;
    overflow: auto;

    display: block;
    border-radius: $modal-border-radius;
    border: 1px solid #333;

    backdrop-filter: blur(25px);
    color: #FFF;
    font-size: 1rem;

    z-index: 11;

    .modal-content {
        display: flex;
        align-items: stretch;
        justify-content: center;
    }

    #csg-image-col {
        flex: 1;
    }

    #csg-details-col {
        flex: 4;
        height: inherit;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: start;

        #points-col {
            display: flex;
            flex: 0 0 70px;
            flex-direction: column;
            justify-content: space-evenly;

            background-color: grey;
            height: 70px;
            background-color: #222222;
            border: 2px solid #000;

            #point-cost {
                font-family: minister, serif;
                font-size: 2.4rem;
                margin-bottom: -10px;
                margin-top: -5px;
            }

            #title {
                font-family: windlass, serif;
                font-size: 0.8rem;
            }
        }
    }

    .link-icon {
        height: 35px;
    }
    .title-and-stats-row {
        align-items: stretch;
    
        #stats-col {
            padding-top: 5px;
            #faction {
                flex: 0 0;
                margin-left: -8px;
                img {
                    box-shadow: 0.5px 0.5px 3px 0.5px #000;
                }
            }
    
            #name-col {
                font-size: 2rem;
                text-align: left;
                padding-left: 15px;
                line-height: 2.1rem;
                flex: 100;
    
                #name {
                    font-family: windlass, serif;
                }
            }

            .stats-row {
                font-family: minister, serif;
                font-size: 1.7rem;

                .row {
                    align-items: stretch;
                    justify-content: start;
                }

                .col {
                    margin: 0 1px;
                }

                .stat:first-child {
                    padding-left: 70px;
                    margin-left: -66px;
                    z-index: -1;
                }

                .stat {
                    background-color: #000;
                    padding: 0px 5px;
                    margin-right: 2px;
                    align-items: center;
                }

                .masts-stat .value,
                .cargo-stat .value,
                .base-move-stat .value {
                    margin-top: 5px;
                    padding-left: 6px;
                }

                .cannons-stat .value{
                    flex: 7;
                    padding-right: 4px;
                }

                .base-move-stat .value {
                    flex: 4;
                }

                .icon {
                    display: flex;
                }
            }
        }
    }

    .modal-options-menu {
        margin-top: -20px;
    }

    .link-dropdown {
        display: inline-block;
    
        .hover-helper {
            display: none;
            position: absolute;
            top: 62px;
            margin-left: -10px;
        }
    
        .hover-helper::before {
            content: '';
            position: absolute;
            top: -50px;
            left: -5px;
            width: 50px;
            height: 60px;
        }
    
        .link-tooltip {
            background-color: #111;
            border-radius: $modal-border-radius;
            border: 1px solid #333;
    
            color: #FFF;
            font-size: 1rem;
    
            padding: 10px 15px;
            margin: 10px;
    
            font-family: inherit;
            font-size: 1rem;
            z-index: 15;
        }
    
        .link-tooltip::before {
            content: "";
            position: absolute;
            top: -15px;
            left: 15px;
    
            rotate: 45deg;
    
            width: 10px;
            height: 10px;
    
            margin-top: 20px;
            margin-left: 20px;
    
            border-width: 1px;
            border-style: solid;
            border-color: #333 transparent transparent #333;
    
            background-color: #111;
            backdrop-filter: blur(1px);
            z-index: 14;
        }
    
        &:hover, &:active {
            .link-tooltip, .hover-helper {
                display: block;
            }
        }
    
        .link-icon {
            margin-left: 10px;
        }
    }
    
    .link-row {
        margin-left: 5px;
        justify-content: flex-start;
        align-items: center;
        .col {
            display: flex;
            text-align: left;
            align-items: center;
        }
    }
    
    #ability-col {
        width: 619px;
        margin: 15px 45px 15px 45px;
        padding: 20px 5px;
        border: 2px solid #000;
        line-height: 1.5rem;
        font-size: 1.1rem;
    }
    
    #flavor-text {
        font-style: italic;
        margin-top: 15px;
        max-width: 723px;
        line-height: 1.3rem;
    }
    
    .ability-keyword-text {
        font-weight: 700;
    }
    
    #set-text {
        margin-top: 10px;
        font-family: windlass, serif;
        font-size: 1.5rem;
        min-width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    
        span {
            padding: 0 4px;
        }
    }
    
    #image-container {
        display: block;
        overflow: hidden;
        height: 400px;
        margin: 0 10px;
        border-radius: $image-border-radius;
    
        #csg-item-image {
            display:block;
            padding: 0;
            height: 400px;
            border-radius: $image-border-radius;
        }

        #rarity-tab {
            position: relative;
            top: -50px;
            left: -45px;
    
            padding-top: 2px;
    
            width: 110px;
            height: 75px;
    
            rotate: 45deg;
    
            overflow: hidden;
    
            font-family: montage, serif;
            font-size: 1.4rem;
            font-weight: bold;
            letter-spacing: 1.5px;
            -webkit-text-stroke: 1px black;
    
            .icon {
                display: flex;
                justify-content: center;
                margin-top: -6px;
            }
        }
    }
    
    .close-button-row {
        display: flex;
        flex: 1;
        align-self: end;
        align-items: end;
    
        margin-top: 15px;
    
        .form-button {
            flex: 1;
        }
    
        .close-button {
            width: 125px;
            height: 40px;
        }
    }

    .keywords-row {
        margin: 30px 0 0 45px;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column
    }
    
    .keywords-title {
        font-family: windlass, serif;
        font-size: 1.6rem;
        margin-bottom: 10px;
    }
    
    .keyword-item {
        margin: 5px 0;
        border: 1px solid #333;
        width: 645px;
    
        .title {
            padding: 15px;
            font-family: windlass, serif;
            font-size: 1.5rem;
            cursor: pointer;
    
            .col {
                text-align: left;
            }
        }
    
        .caret {
            text-align: right !important;
            .active {
                rotate: 180deg;
            }
        }
    
        .definition {
            border-top: 1px solid #333;
            padding: 15px 0;
            margin: 0 15px;
            text-align: left;
            line-height: 1.5rem;
        }
    }
}

