@import 'screen';
@import 'variables';

.header-row {
    height: 100px;
    min-width: 600px;

    .header-title {
        flex: 0 1 $max-content-width - 70px;
        display: flex;
        justify-content: space-between;

        font-size: 2rem;
        border: none;

        line-height: 2rem;

        &:hover {
            box-shadow: none;
            border: none;
        }

        .title-link {
            font-family: windlass, serif;
            margin-left: 25px;
            color: #fff;
            text-decoration: none;
            display: flex;
            align-items: center;

            img {
                padding-left: 20px;
            }

            @include breakpoint(tablet) {
                flex: 0 0 auto;
            }
        }
    }

    .login-button {
        font-family: windlass, serif;
        padding: 10px 15px;
        margin-right: 25px;
    }

    @include breakpoint(tablet) {
        min-width: 100%;
        .header-title {
            flex: 1 1 auto;
            width: 100%;

            @media screen and (max-width: 447px) {
                font-size: 1.3rem;
            }

            .title-link {
                img {
                    height: 40px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.header-logo {
    text-align: left;
    padding-left: 20px;
}

.user-dropdown {
    margin-right: 30px;

    .user-dropdown-header {
        font-size: 1.2rem;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #fff;
        position: relative;
        z-index: 5;

        cursor: pointer;

        border: 1px solid transparent;
        padding: 2px 5px;

        font-family: windlass, serif;

        &.active {
            border: 1px solid #333;
            backdrop-filter: blur(5px);
        }

        .dropdown-arrow {
            &.active {
                transform: rotate(180deg);
            }
        }
    }

    .user-dropdown-content {
        display: none;
        position: relative;
        z-index: 4;
        font-size: 1rem;

        ul {
            position: absolute;
            top: -17px;
            right: 0px;
            padding: 3px 8px;
            border: 1px solid #333;

            backdrop-filter: blur(25px);
            box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.3);

            .user-dropdown-button {
                padding: 5px;
                list-style: none;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            li + li {
                border-top: 1px solid #333;
            }
        }

        &.active {
            display: block;
        }
    }
}

.nav-bar {
    .nav-link {
        margin: 0 10px;
        padding: 4px 2px;
        font-size: 1.2rem;
        font-family: windlass, serif;
        border: none;
        border-bottom: 2px solid transparent;
        box-shadow: none;

        &:hover {
            border-bottom: 2px solid white;
        }

        &.active {
            border-bottom: 2px solid orange;
        }

    }
}

.mobile-menu-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
}

.mobile-menu-modal {
    position: fixed;
    top: 0;
    right: -250px;
    z-index: 100;
    width: 175px;

    align-items: start;

    height: 100%;
    padding: 5px;

    border-left: 1px solid white;

    text-align: left;

    backdrop-filter: blur(25px);

    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    box-shadow: 0 0 10px 2px #000;

    .links-col {
        text-align: left;
        flex: 9;
        padding: 10px;

        .mobile-link-button {
            list-style: none;
            margin-bottom: 10px;
            font-size: 1.2rem;

            .link-icon {
                svg {
                    height: 17px;
                    width: 17px;
                    fill: #fff;
                    padding-right: 8px;
                }
            }

            a {
                color: white;
                text-decoration: none;
                border-bottom: 2px solid transparent;
            }

            &.active a{
                border-bottom: 2px solid orange;
            }
        }
    }

    .close-button-col {
        font-size: 2rem;
        font-weight: 100;
    }

    &.active {
        right: 0;
    }
}

.mobile-menu-toggle {
    margin-right: 25px;
}
