:root{
    color-scheme: light only;
}

html {
    color: white;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.background-image {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 115vh;
    z-index: -1;
    overflow-x: hidden;
    background-image: url('images/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 95% center;
}

.row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: unset;
    flex-direction: row;
}

.col {
    align-items: center;
    text-align: center;
    flex: 1;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

