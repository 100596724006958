@import 'csgModal';

.modal-options-button {
    cursor: pointer;
    flex: 1;
    margin-top: -30px;

    svg {
        fill: #ddd;
    }
}

.modal-options-menu {
    .modal-options-menu-content {
        position: absolute;
        right: 5px;
        z-index: 15;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        background-color: #111;
        border-radius: $modal-border-radius;
        border: 1px solid #333;
        padding: 10px 15px;
        margin: 10px;

        color: #fff;
        text-align: left;
        font-size: 1rem;

        .menu-button {
            list-style: none;
            margin: 5px 0;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .link-icon svg {
            fill: #fff;
            margin-right: 7px;
        }
    }
}
