@import 'csgModal';

.mobile-modal {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    overflow: auto;

    .background {
        position: fixed;
        bottom: 0;
        width: 100vw;
        height: 120vh;
        z-index: -1;
        overflow-x: hidden;
        backdrop-filter: blur(25px);
    }

    z-index: 11;

    // provides horizontal scroll if window too small
    // and internal margin on the modal
    .mobile-modal-content {
        min-width: 301px;
        padding: 10px;
    }

    .modal-options-menu {
        margin-top: -5px;

        .modal-options-menu-content {
            right: 0;
        }
    }

    .image-container {
        overflow: hidden;
        margin-top: 20px;
        border-radius: $image-border-radius;

        #csg-item-image {
            width: 100%;
            display: block;
        }

        .rarity-tab-container {
            height: 0px;

            #rarity-tab {
                position: relative;
                top: -50px;
                left: -45px;
                text-align: center;

                padding-top: 2px;

                width: 110px;
                height: 75px;

                rotate: 45deg;

                overflow: hidden;

                font-family: montage, serif;
                font-size: 1.4rem;
                font-weight: bold;
                letter-spacing: 1.5px;
                -webkit-text-stroke: 1px black;

                .icon {
                    display: flex;
                    justify-content: center;
                    margin-top: -6px;
                }
            }
        }
    }

    .set-text {
        margin-top: 5px;
        font-family: windlass, serif;
        font-size: 1.4rem;
        line-height: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            padding: 0 4px;
        }
    }

    .ability-row {
        margin: 20px 0;
        padding: 20px 5px;
        border: 2px solid #000;

        .ability-keyword-text {
            font-weight: 700;
        }
    }

    .link-icon {
        height: 35px;
    }

    .csg-item-link-row {
        margin-top: 7px;
    }

    #flavor-text {
        font-style: italic;
        text-align: center;
        margin-top: 15px;
    }

    .keywords-row {
        margin-top: 30px;

        .keywords-title {
            font-family: windlass, serif;
            font-size: 1.5rem;
            margin-bottom: 10px;
        }

        .keyword-item {
            margin: 5px 0;
            border: 1px solid #333;
            padding: 15px;

            .title {
                text-align: left;
                font-family: windlass, serif;
                font-size: 1.5rem;
                line-height: 1.5rem;
            }
            .caret {
                text-align: right;
            }

            .definition {
                border-top: 1px solid #333;
                padding-top: 15px;
                margin-top: 15px;
                text-align: left;
                line-height: 1.3rem;
            }
        }
    }

    .close-button-row {
        width: 100%;

        .form-button {
            flex: 1;
            margin: 45px 15px 15px;

            .close-button {
                width: 100%;
                height: 50px;
            }
        }
    }

    .csg-item-header {
        justify-content: start;
        align-items: stretch;

        .points-col {
            display: flex;
            flex: 0 0 60px;
            flex-direction: column;
            justify-content: space-evenly;

            background-color: grey;
            height: 60px;
            background-color: #222222;
            border: 2px solid #000;

            #point-cost {
                font-family: minister, serif;
                font-size: 2.0rem;
                margin-bottom: -10px;
                margin-top: -5px;
            }

            #title {
                font-family: windlass, serif;
                font-size: 0.8rem;
            }
        }

        .faction-title-col {
            padding-top: 5px;

            .title-col {
                flex: 10;
                font-family: windlass, serif;
                font-size: 1.3rem;
                text-align: left;
                padding-left: 15px;
                line-height: 1.5rem;
                padding-bottom: 28px;
            }

            .faction-col {
                flex: 0 0;
                align-self: start;

                span {
                    margin: -8px;
                }

                img {
                    box-shadow: 0.5px 0.5px 3px 0.5px #000;
                }
            }
        }
    }

    .stats-row {
        font-family: minister, serif;
        font-size: 1.4rem;
        margin-left: 5px;
        margin-top: -28px;
    
        .row {
            align-items: stretch;
            justify-content: start;
            flex-wrap:wrap;
        }
    
        .stat:first-child {
            padding-left: 65px;
            z-index: -1;
        }
    
        .stat {
            background-color: #000;
            padding: 0px 7px 2px;
            margin: 2px 2px 0 0;
            align-items: center;
        }

        .col {
            margin: 0 1px;
        }

        .value {
            padding-left: 5px;
        }

        .cargo-stat .value {
            margin-top: 4px;
        }

        .masts-stat .value {
            margin-top: 4px;
        }

        .cannons-stat .value{
            flex: 8;
        }

        .base-move-stat .icon {
            flex: 1;
        }

        .base-move-stat .value {
            flex: 4;
            margin-top: 4px;
        }

        .icon {
            display: flex;
        }
    }
}
