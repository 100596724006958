.notification-container {
    position: fixed;
    align-self: end;
    top: 100px;
    width: 250px;
    margin-right: 35px;
    z-index: 99;

    min-height: 100px;

    .notification {
        position: relative;

        display: flex;
        align-items: center;

        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;

        &.error {
            background-color: rgb(246, 92, 92);
            border: 1px solid rgb(183, 17, 17);
        }
    
        &.info {
            background-color: rgb(48, 157, 218);
            border: 1px solid rgb(0, 114, 177);
        }

        &.success {
            background-color: rgb(45, 158, 9);
            border: 1px solid rgb(40, 133, 10);
        }

        .icon {
            flex: 1;
            
            svg {
                height: 25px;
                fill: #fff;
            }
        }

        .message {
            flex: 8;
            padding: 0 5px;
        }
    }
}
