@use "sass:map";

@import 'screen';
@import 'variables';

$row-border-color: #555;
$row-border-hover-color: #eee;

$raritiesColors: (
    "common": red,
    "uncommon": linear-gradient(rgba(170,169,173,1) 15%, rgba(85,85,85,1) 50%, rgba(170,169,173,1) 85%),
    "rare": linear-gradient(rgba(228,197,31,1) 15%, rgba(133,116,27,1) 50%, rgba(228,197,31,1) 85%),
    "super-rare": black,
    "promo": linear-gradient(rgba(20,74,3,1) 0%, rgba(76,166,48,1) 40%, rgba(142,199,126,1) 50%, rgba(76,166,48,1) 60%, rgba(20,74,3,1) 100%),
    "le": linear-gradient(rgba(114,66,40,1) 20%, rgba(171,133,112,1) 40%, rgba(236,193,170,1) 50%,rgba(171,133,112,1) 60%,rgba(114,66,40,1) 80%),
    "pr": linear-gradient(rgba(20,74,3,1) 0%, rgba(76,166,48,1) 40%, rgba(142,199,126,1) 50%, rgba(76,166,48,1) 60%, rgba(20,74,3,1) 100%),
    "se": linear-gradient(rgba(20,74,3,1) 0%, rgba(76,166,48,1) 40%, rgba(142,199,126,1) 50%, rgba(76,166,48,1) 60%, rgba(20,74,3,1) 100%),
    "special": blue,
    "one-of-one": linear-gradient(rgba(35,216,218,1) 20%, rgba(26,126,126,1) 40%, rgba(20,98,98,1) 50%, rgba(26,126,126,1) 60%, rgba(35,216,218,1) 80%)
);

$faction-icons: (
    "american": url("../faction-icons/america.png"),
    "barbary-corsair": url("../faction-icons/corsairs.png"),
    "cursed": url("../faction-icons/cursed.png"),
    "english": url("../faction-icons/england.png"),
    "french": url("../faction-icons/france.png"),
    "jade-rebellion": url("../faction-icons/jade.png"),
    "mercenary": url("../faction-icons/mercenary.png"),
    "pirate": url("../faction-icons/pirate.png"),
    "spanish": url("../faction-icons/spain.png"),
    "viking": url("../faction-icons/viking.png"),
    "whitebeards-raiders": url("../faction-icons/whitebeardraiders.png"),
);

#header-row {
    font-size: 1.3rem;
    font-family: windlass, serif;
    align-items: stretch;
    height: 80px;
    position: sticky;
    top: 0;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    &.scrolling {
        box-shadow: inset 0px 0px 80px 80px rgba(0, 0, 0, 0.95), 0px 0px 26px 26px rgba(0, 0, 0, 0.95);
    }

    .field-icon {
        height: 25px;
    }

    .link-icon {
        height: 35px;
    }

    .header-col {
        justify-content: space-between;
        text-align: center;

        .sortable-header {
            cursor: pointer;
        }

    }

    .rarity-col {
            margin-left: -2px;
    }

    .csg-col {
        display: flex;
        flex-direction: column;
    }

    .sort-order {
        height: 20px;
        align-self: center;


        svg {
            display: none;
        }

        &.ascending {
            transform: scaleY(-1);

            &.show svg {
                display: block;
            }
        }

        &.descending {
            &.show svg {
                display: block;
            }
        }
    }

    @include breakpoint(tablet) {
        font-size: 1.0rem;

        .field-icon {
            height: 20px;
        }

        .link-icon {
            height: 25px;
        }
    }
}

#csg-list {
    max-width: ($max-content-width - 70px);
    flex: 1 1 100%;
    min-width: 700px;

    @include breakpoint(tablet) {
        margin: 15px 0 0;
        min-width: 0;
    }
}

.windlass-font {
    -webkit-text-stroke: 1px black;
    font-family: windlass, serif;
    font-weight: 700;
    font-size: 1.4rem;
    color: #fff;
}

.csg-row {
    padding: 0;
    margin: 5px;
    height: 60px;

    @include breakpoint(phone) {
        height: 75px;
    }

    &.no-items {
        font-family: windlass, serif;
        font-size: 1.3rem;
        border: 2px solid transparent;
    }

    &.edit-mode-green {
        background-color: rgba(0, 255, 0, 0.1);
    }

    &.edit-mode-red {
        background-color: rgba(255, 0, 0, 0.1);
    }
}

.csg-item-row {
    border: 2px solid $row-border-color;
    cursor: pointer;

    &:hover {
        border: 2px solid $row-border-hover-color;
        box-shadow: 0 0 4px 1px $row-border-hover-color;
    }
}

.csg-col {
    padding: 5px 0;
}

.rarity-col {
    flex: 1;
}

.set-col {
    flex: 2;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

.faction-col {
    flex: 2;
}

.id-col {
    flex: 3;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

.name-col {
    flex: 5;
}

.type-col {
    flex: 2;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

.pointCost-col {
    flex: 2;
}

.masts-col {
    flex: 3;
}

.cargo-col {
    flex: 2;
}

.baseMove-col {
    flex: 5 0;

    @include breakpoint(phone) {
        display: none !important;
    }
}

.owned-col {
    font-family: windlass, serif;
    flex: 2 0;

    .owned {
        color: green;
        font-size: 1.6rem;;
    }

    .not-owned {
        color: red;
        font-size: 1.8rem;;
    }
}

.cannons-col {
    flex: 7 0;

    @include breakpoint(phone) {
        display: none !important;
    }
}

.link-col {
    flex: 5;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

.ability-col {
    flex: 8;
    margin-left: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

@each $rarity, $color in $raritiesColors {
    .#{$rarity}-col {
        display: flex;
        height: 100%;
    
        .#{$rarity} {
            @if $rarity == common or $rarity == super-rare or $rarity == special {
                background-color: $color;
            } @else {
                background-image: $color;
            }
            width: 15px;
            border-right: 1px solid #000;
        }
    }
}

@each $faction, $icon in $faction-icons {
    .#{$faction}-col {
        .faction-image {
            height: 25px;
        }

        @include breakpoint(phone) {
            .faction-image {
                height: 20px;
            }
        }
    }
}

