.loading-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 100%;
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    background: rgba(0, 0, 0, 0.3);

    .loading-container {
        flex: 3;
    }

    .empty-row {
        flex: 1;
    }
}

