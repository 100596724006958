@import 'variables';
@import 'screen';

$raritiesColors: (
    "common": red,
    "uncommon": linear-gradient(90deg, rgba(170,169,173,1) 20%, rgba(94,94,94,1) 40%, rgba(55,55,55,1) 50%, rgba(94,94,94,1) 60%, rgba(170,169,173,1) 80%),
    "rare": linear-gradient(90deg, rgba(228,197,31,1) 20%, rgba(148,129,32,1) 40%, rgba(120,104,30,1) 50%, rgba(148,129,32,1) 60%, rgba(228,197,31,1) 80%),
    "super-rare": black,
    "promo": linear-gradient(90deg, rgba(20,74,3,1) 20%, rgba(76,166,48,1) 40%, rgba(142,199,126,1) 50%, rgba(76,166,48,1) 60%, rgba(20,74,3,1) 80%),
    "le": linear-gradient(90deg, rgba(114,66,40,1) 20%, rgba(171,133,112,1) 40%, rgba(236,193,170,1) 50%,rgba(171,133,112,1) 60%,rgba(114,66,40,1) 80%),
    "pr": linear-gradient(90deg, rgba(20,74,3,1) 20%, rgba(76,166,48,1) 40%, rgba(142,199,126,1) 50%, rgba(76,166,48,1) 60%, rgba(20,74,3,1) 80%),
    "se": linear-gradient(90deg, rgba(20,74,3,1) 20%, rgba(76,166,48,1) 40%, rgba(175,219,163,1) 50%, rgba(76,166,48,1) 60%, rgba(20,74,3,1) 80%),
    "special": blue,
    "one-of-one": linear-gradient(90deg, rgba(35,216,218,1) 20%, rgba(26,126,126,1) 40%, rgba(20,98,98,1) 50%, rgba(26,126,126,1) 60%, rgba(35,216,218,1) 80%)
);


.result-content {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    width: 100%;
}

#content {
    display: flex;
    width: 100%;
}

.page-edit-container {
    display: flex;

    width: 100%;
    margin-top: 30px;
}


.edit-collection-button-group {
    margin-left: 40px;

    @include breakpoint(tablet) {
        margin-left: 10px;
        bottom: 5px;
    }

    &.mobile {
        position: fixed;
        bottom: 10px;

        align-self: flex-start;
        margin-left: 10px;
    }

    .edit-collection-button,
    .discard-edit-button,
    .save-edit-button {
        display: flex;
        align-content: center;

        border-radius: 25px;
        padding: 10px 10px;
        backdrop-filter: blur(25px);
    }

    .edit-collection-button {
        svg {
            fill: #fff;
        }

        .edit-button-text {
            display: flex;
            margin-left: 10px;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    .save-edit-button {
        margin-right: 10px;

        svg {
            fill: #7D88FC;
        }

        .save-edit-button-text {
            display: flex;
            margin-left: 10px;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    .discard-edit-button {
        svg {
            fill: #F65A5A;
        }

        .discard-edit-button-text {
            display: flex;
            margin-left: 10px;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }
}

.page-control {
    flex: 1;
    .col {
        flex: 0;
    }

    &.upper {
        justify-content: end;

        margin-right: 40px;

        @include breakpoint(tablet) {
            justify-content: center;
            margin-right: 0px;
        }
    }

    &.lower {
        justify-content: center;
        margin-top: 20px;
    }

    .page-button {
        width: 90px;
        height: 40px;

        svg {
            path {
                fill: #fff;
            }
        }

        &.previous svg {
            transform: scaleX(-1);
        }

        &.disabled svg path {
            fill: #999;
        }

        @include breakpoint(phone) {
            width: 40px;
            height: 30px;
        }
    }

    #page-number-list .row:first-child{
        justify-content: space-evenly;

        .ellipsis {
            width: 15px;
        }
        .page-number-col {
            width: 50px;

            @include breakpoint(phone) {
                width: 40px;
            }
        }
    }

    .page-number-col {
        cursor: pointer;
    }

    .page-number-col:hover {
        text-decoration: underline;
    }

    .ellipsis {
        color: $disabled;
        display: flex;
    }

    .page-size-select-col {
        margin: 0 25px;

        .col {
            margin: 5px;
        }
    }

    .page-size-select {
        background: #444;
        border-radius: 5px;
        padding: 5px;
        color: white;
        option:hover {
            box-shadow: 0 0 10px 10px #000 inset;
        }
    }

    @include breakpoint(phone) {
        flex-direction: column-reverse;
    }
}


.query-content {
    flex: 1;
    flex-direction: column;

    margin-top: 50px;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.search-row {
    flex: 1 1 100%;
    width: 100%;

    .form-input {
        #search-text-box {
            min-width: 900px;
            width: 900px;
        }

        @include breakpoint(tablet) {
            flex: 1;
            margin: 0 30px;

            #search-text-box {
                width: 100%;
                min-width: 100%;
            }
        }
    }
}

.faction-row {
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 15px;
    flex: 1;
    width: 100%;

    .faction-toggle {
        cursor: pointer;
        padding: 0 0 0 0;
        background-color: transparent;
        border: none;
        margin: 10px;
        overflow: hidden;

        .faction-image {
            object-fit: contain;
            height: 100%;
            height: 40px;
            display: block; // prevents small space under image
        }

        &.toggled {
            box-shadow: 0 0 10px 2px #bbb;
        }
    }

    @include breakpoint(phone) {
        .faction-toggle{
            margin: 10px 15px 0 15px;
            .faction-image {
                height: 30px;
            }
        }
    }
}

.advanced-filters-row {
    width: 80%;
    margin-top: 20px;

    .advanced-filters-title .col {
        text-align: right;

        span {
            cursor: pointer;
        }

        .title-text {
            padding-right: 4px;
        }

        .down-arrow {
            transition: transform 0.2s linear;
            &.active {
                transform: rotate(-180deg);
            }
        }

        .active-filter-alert-icon {
            height: 15px;
            width: 15px;
            padding-right: 10px;
            margin-bottom: -2px;
            fill: #ff6600;
        }
    }

    .advanced-filters-content {
        border: 2px solid #333;
        max-height: 0;
        overflow: hidden;

        transition: max-height 0.2s ease-in-out, padding 0.1s ease-in-out;

        &.show {
            padding-top: 15px;
            max-height: 1500px;
        }
    }

    .row {
        margin: 10px 0;

        .col {
            margin: 0 10px;
        }
    }

    .slider-title {
        text-align: left;
    }

    .point-cost-container, .masts-container, .cargo-container {
        padding: 25px 10px;
        border-style: solid;
        border-color: #333;
    }

    .point-cost-slider, .masts-slider, .cargo-slider {
        flex: 9;
    }

    .input-filter-row {
        align-items: stretch;
        flex-wrap: wrap;
        margin-top: 20px;

        .number-inputs {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .masts-input, .cargo-input {
                height: 22px;
            }
        }

        .multi-dropdown-row {
            margin: 0;

            .multi-dropdown-col:first-child {
                .row {
                    margin: 0;
                }

                & > .row {
                    margin-bottom: 25px;

                }
            }
        }
    }

    .masts-input, .cargo-input {
        width: 75px;
        margin: 0 7px;
    }

    .rarities {
        @each $rarity, $color in $raritiesColors {
            ##{$rarity}:before {
                @if $rarity == common or $rarity == super-rare or $rarity == special {
                    background-color: $color;
                } @else {
                    background-image: $color;
                }

                @if $rarity == super-rare {
                    border: 1px solid #555;
                } @else {
                    border: 1px solid #000;
                }

                height: 100%;
                display: inline-block;
                content: '';
                width: 11px;
                height: 11px;
                margin-right: 5px;
            }
        }
    }

    .icons-only {
        @each $rarity, $color in $raritiesColors {
            ##{$rarity} {
                display: flex;
                align-items: center;
            }

            ##{$rarity} span {
                @if $rarity == se or $rarity == promo or $rarity == pr {
                    font-size: 0.8rem;
                    flex-wrap: nowrap;
                } @else {
                    display: none;
                }
            }
            ##{$rarity}:before {
                content: '';
                @if $rarity == common or $rarity == super-rare or $rarity == special {
                    background-color: $color;
                } @else {
                    background-image: $color;
                }

                @if $rarity == super-rare {
                    border: 1px solid #555;
                } @else {
                    border: 1px solid #000;
                }

                height: 100%;
                display: inline-block;
                content: '';
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }
        }
    }

    .selected-base-movement, .selected-type {
        .toggled-item {
            padding: 4px 2px 3px 2px;
            border: 1px solid #333;
            background-color: rgba(55, 55, 55, 0.4);
            border-radius: 4px;
        }
    }

    .base-move {
        font-family: windlass, serif;
        font-size: 1.1rem;
    
        .red {
            color: red;
        }
    }

    .set-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .set-icon {
            padding-right: 4px;
        }

        .set-abbreviation {
            display: none;
        }
    }

    .selected-set {
        .toggled-item {
            border: 1px solid #333;
            background-color: rgba(55, 55, 55, 0.4);
            border-radius: 4px;
            padding: 0 4px;
        }

        .unreleased {
            padding: 4px 2px 3px 2px;
        }

        .set-name {
            display: none;
        }

        .set-abbreviation {
            display: block;
        }
    }

    .buttons-row {
        justify-content: end;

        .clear-button, .search-button {
            width: 100px;
            padding: 10px 0;
            margin: 0 15px;
        }

        @include breakpoint(phone) {
            justify-content: center;
        }
    }

    .name-ability-row {
        flex-wrap: wrap;

        .name-filter {
            flex: 5;
            min-width: 276px;
        }

        .ability-filter {
            flex: 5;
            min-width: 276px;
        }
    }

    .name-filter, .ability-filter, .flavor-text-filter {
        padding: 10px 0;
    }

    .keyword-info {
        width: 100%;

        .keyword-icon {
            position: relative;
            float: right;
            cursor: pointer;

            height: 20px;
            width: 20px;
            z-index: 1;

            margin-top: -25px;

            fill: #fff;
        }
    }
}


.info-modal-overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.3);

    .info-modal {
        border: 1px solid #333;
        backdrop-filter: blur(25px);
        padding: 25px 30px 20px 30px;

        .title-row {
            font-size: 1.6rem;
            margin-bottom: 30px;

            & > .col {
                text-align: left;
            }
        }

        .content-row {
            font-size: 1.05rem;
            margin-bottom: 30px;
        }

        .close-button {
            width: 100px;
            padding: 10px 0;
            margin: 0 15px;
        }
    }
}
