@import 'screen';
@import 'variables';

@font-face {
    font-family: 'windlass';
    src: url('../../public/fonts/Windlass.woff');
}

@font-face {
    font-family: 'minister';
    src: url('../../public/fonts/ministerstd-light.otf');
}

@font-face {
    font-family: 'montage';
    src: url('../../public/fonts/montage.regular.ttf');
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;

    .page-content {
        min-height: 60vh;
        display: flex;
        flex: 1 1 $max-content-width;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-bottom: 60px;
        max-width: $max-content-width;
    }
}

#backdrops {
    width: 100%;
    opacity: 0.8;
    position: absolute;
    z-index: -1;

    #header-backdrop {
        position: absolute;
        top: -10px;
        right: -10px;
    
        height: 105px;
        width: 110%;
    
        background-color: #000;
        filter: blur(6px);
    
        z-index: -1;

        @include breakpoint(tablet) {
            display: none;
        }
    }
    
    #page-content-backdrop {
        background-color: #000;
        filter: blur(6px);
    
        max-width: $max-content-width;
        min-width: $max-content-width;
        height: 130%;
    
        position: fixed;
        top: -15px;
        left: 50%;
    
        transform: translateX(-50%);
        z-index: -1;
    }
}

