$container-min-width: 250px;

.login-container {
    width: 95%;
    & > .col {
        flex: 0 1 450px;
    }

    .username-login-input, .password-login-input {
        margin-bottom: 30px;
        width: 100%;
    }

    .form-button {
        width: 100%;
    }

    #login-button {
        width: 100%;
        padding: 10px 0;
        margin-bottom: 20px;
    }

    #register-button {
        display: block;
        text-align: center;
        padding: 10px 0;
        margin-bottom: 20px;
    }

    .forgot-links-row {
        .forgot-link {
            display: block;
            margin: 0 8px;
            border: none;
    
            &:hover {
                border: none;
                box-shadow: none;
                text-decoration: underline;
            }
        }
    }
}

